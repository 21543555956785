import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';

// Keyframes for animations (e.g., text glow and image shake)
const textGlow = keyframes`
  0% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 0, 0, 1);
  }
  100% {
    text-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  }
`;

const imageShake = keyframes`
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(4px, -4px);
  }
  50% {
    transform: translate(-4px, 4px);
  }
  75% {
    transform: translate(4px, 4px);
  }
`;

// Styling for the main container with a black-to-red gradient background
const ErrorPageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  background: 'linear-gradient(to bottom, black, red)', // Black to red gradient background
  color: '#fff',
  textAlign: 'center',
});

// Typography styling for the error message with glowing animation
const ErrorMessage = styled(Typography)({
  fontSize: '120px',
  fontWeight: 'bold',
  color: 'white',
  animation: `${textGlow} 2s infinite ease-in-out`,
  marginBottom: '20px',
});

// Styling for the error description text
const ErrorDescription = styled(Typography)({
  fontSize: '24px',
  color: '#fff',
  marginBottom: '20px',
});

// Styling for a bouncing image animation
const AnimatedImage = styled('img')({
  height: '150px',
  marginBottom: '20px',
  animation: `${imageShake} 1.5s infinite ease-in-out`,
});

// Styling for the "Go Home" button with hover effect
const StyledButton = styled(Button)({
  fontSize: '18px',
  backgroundColor: '#ff0000', // Red button
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '30px',
  transition: 'transform 0.3s',
  '&:hover': {
    backgroundColor: '#d50000',
    transform: 'scale(1.1)',
  },
});

const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <ErrorPageContainer>
      <ErrorMessage variant="h1">404</ErrorMessage>

      <AnimatedImage 
        src="https://via.placeholder.com/150/FF0000/FFFFFF?text=404" 
        alt="404 icon" 
      />

      <ErrorDescription variant="h5">
        Oops! The page you're looking for doesn't exist.
      </ErrorDescription>

      <StyledButton 
        variant="contained" 
        onClick={() => navigate('/')}
      >
        Go Back Home
      </StyledButton>
    </ErrorPageContainer>
  );
};

export default Error404Page;
