import { logoutUser } from '../routes/api';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import AppHeader from './AppHeader';
import Footer from './Footer';

const VideoBackground = styled('video')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: '-1',
  opacity: 0.7,
});

const HomePageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  position: 'relative',
  minHeight: '100vh',
  overflow: 'hidden',
});

const WelcomePaper = styled('div')(({ theme }) => ({
  padding: '3rem',
  maxWidth: '800px',
  width: '100%',
  textAlign: 'center',
  borderRadius: '20px',
  boxShadow: '0px 6px 30px rgba(0, 0, 0, 1)',
  zIndex: '10',
  marginTop: '10vh',
  position: 'relative',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.2)', // Optional border for the frosted effect
  [theme.breakpoints.down('sm')]: {
    padding: '2rem',
  },
}));

const WelcomeText = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 700,
  color: 'white',
  marginBottom: '2rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem',
  },
}));

const StyledButton = styled(Button)(({ theme, ...props }) => ({
  padding: '10px 20px',
  borderRadius: '12px',
  fontSize: '1rem',
  fontWeight: 600,
  transition: 'all 0.3s ease-in-out',
  width: '100%',
  maxWidth: '250px',
  backgroundColor: props.variant === 'contained' ? 'red' : 'white',
  color: props.variant === 'contained' ? '#fff' : 'red',
  border: props.variant === 'outlined' ? `2px solid red` : 'none',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.4)',
    backgroundColor: props.variant === 'contained' ? '#D32F2F' : 'rgba(255, 0, 0, 0.1)',
  },
}));

const ButtonContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  marginTop: '2rem',
});

const LogoImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  marginBottom: '2rem',
});

const HomePage = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(localStorage.getItem('userId'));

  const handleLogout = async () => {
    await logoutUser();
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    navigate('/login');
  };

  const handleViewProfile = () => {
    navigate(`/users/${userId}`);
  };

  return (
    <>
      <AppHeader userId={userId} onLogout={handleLogout} onViewProfile={handleViewProfile} />
      <HomePageContainer>
        <VideoBackground autoPlay loop muted>
          <source src={`http://backend.tim3.cortexakademija.com/video.mp4`} type="video/mp4" />
        </VideoBackground>
        <WelcomePaper>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <LogoImage src={`${process.env.PUBLIC_URL}/Need4Rent.png`} alt="Classic Car" />
            </Grid>
            <Grid item xs={12} md={6}>
            <ButtonContainer container spacing={2}>
              <Grid item>
                <StyledButton variant="contained" onClick={() => navigate('/vehicles')}>
                  View Vehicles
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton variant="outlined" onClick={() => navigate('/reservations')}>
                  View My Reservations
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton variant="contained" onClick={() => navigate('/vehicle-tracking')}>
                  Track Vehicles
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton variant="contained" onClick={() => navigate('/about-us')}>
                  About Us
                </StyledButton>
              </Grid>
            </ButtonContainer>
            </Grid>
          </Grid>
        </WelcomePaper>
      </HomePageContainer>
      <Footer />
    </>
  );
};

export default HomePage;
