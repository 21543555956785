import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const apiRequest = async (method, endpoint, data = {}, requiresAuth = false) => {
    const config = {
        method: method,
        url: `${API_URL}${endpoint}`,
        headers: {},
        data: data,
    };

    if (requiresAuth) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    }

    if (method.toLowerCase() === 'post' && data._method) {
        config.headers['Content-Type'] = 'multipart/form-data';
        data.delete('_method'); 
    }

    if (method.toLowerCase() === 'get') {
        delete config.data;
    }

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default apiRequest;

