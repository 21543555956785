import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../routes/api';
import Swal from 'sweetalert2';
import { styled } from '@mui/system';


const ProtectedRoute = ({ element: Component, ...rest }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        if (!token || !userId) {
            navigate('/login');
            return;
        }

        const validateToken = async () => {
            try {
                await getUserProfile(userId);
                setIsAuthenticated(true);
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Token',
                    text: 'Your session has expired. You will need to log in again',
                    confirmButtonText: 'OK',
                });

                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                navigate('/login');
            } finally {
                setIsLoading(false);
            }
        };

        validateToken();
    }, [navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? (
        <div>
            <Component {...rest} />
        </div>
    ) : null;
};

export default ProtectedRoute;
