import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getVehicleDetails, createReservation, getVehicleReviews, createVehicleReview } from '../../routes/api';
import { Button, Typography, CircularProgress, Card, CardContent, CardMedia, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';
import AppHeader from '../AppHeader';
import StarRating from './StarRating';

const VehicleDetailContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(to bottom, black, red)',
    color: '#fff',
});

const VehicleCard = styled(Card)(() => ({
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    maxWidth: '600px',
    margin: '20px',
    color: '#000',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.02)',
    },
}));

const Footer = styled('footer')({
    padding: '20px',
    backgroundColor: 'rgba(255, 0, 0, 0.9)',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.5)',
    position: 'relative',
    textAlign: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    color: '#fff',
});

const RatingBox = styled('div')({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    maxWidth: '300px',
    margin: '20px auto',
    textAlign: 'center',
});

const VehicleDetailPage = () => {
    const { id } = useParams();
    const [vehicle, setVehicle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reviews, setReviews] = useState([]);
    const [rating, setRating] = useState('');
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchVehicleDetails = async () => {
            try {
                setLoading(true);
                const response = await getVehicleDetails(id);
                if (response.data) {
                    setVehicle(response.data);
                    const reviewsResponse = await getVehicleReviews(id);
                    setReviews(reviewsResponse.data);
                } else {
                    throw new Error("Vehicle not found.");
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicleDetails();
    }, [id]);

    const handleReserveClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitReservation = async () => {
        if (!startDate || !endDate) {
            Swal.fire('Error', 'Please enter valid start and end dates.', 'error');
            return;
        }

        const formData = {
            vehicle_id: id,
            user_id: userId,
            start_date: startDate,
            end_date: endDate,
        };

        try {
            await createReservation(formData);
            setOpen(false);
            Swal.fire('Success', 'Reservation created successfully!', 'success');
        } catch (error) {
            Swal.fire('Error', error.message || 'Failed to create the reservation. Please try again.', 'error');
        }
    };

    const handleRatingSubmit = async () => {
        if (!rating) {
            Swal.fire('Error', 'Please provide a rating.', 'error');
            return;
        }

        const reviewData = {
            vehicle_id: id,
            user_id: userId,
            rating: parseInt(rating),
        };

        try {
            await createVehicleReview(reviewData);
            Swal.fire('Success', 'Rating submitted successfully!', 'success');
            setReviews([...reviews, reviewData]);
            setRating('');
        } catch (error) {
            Swal.fire('Error', 'Failed to submit the rating. Please try again.', 'error');
        }
    };

    const calculateAverageRating = () => {
        if (reviews.length === 0) return 0;
        const total = reviews.reduce((acc, review) => acc + review.rating, 0);
        return (total / reviews.length).toFixed(1);
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography variant="h5" color="error">Error: {error}</Typography>
            </div>
        );
    }

    if (!vehicle) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography variant="h5">No vehicle details available.</Typography>
            </div>
        );
    }

    const averageRating = calculateAverageRating();

    return (
        <VehicleDetailContainer>
            <AppHeader userId={userId} />
            <Typography variant="h4" align="center" gutterBottom>
                {vehicle.brand} {vehicle.model} Details
            </Typography>

            <VehicleCard>
                <CardMedia
                    component="img"
                    height="300"
                    image={`http://backend.tim3.cortexakademija.com/${vehicle.image}` || 'https://gtacars.net/images/0ea9863b0ff61f913b77744cec5d3400'}
                    alt={`${vehicle.brand} ${vehicle.model}`}
                    style={{ borderRadius: '10px 10px 0 0' }}
                />
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        <strong>Type:</strong> {vehicle.type}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Status:</strong> {vehicle.status}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Location:</strong> {vehicle.location}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Description:</strong> {vehicle.description}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        <strong>Price per Day:</strong> ${vehicle.price_per_day}
                    </Typography>

                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Button variant="contained" color="primary" size="large" onClick={handleReserveClick}>
                            Reserve Now
                        </Button>
                    </div>
                </CardContent>
            </VehicleCard>

            <Typography variant="h5" align="center" gutterBottom>
                Average Rating: {averageRating}
            </Typography>
            <StarRating rating={parseFloat(averageRating)} />

            <RatingBox>
                <TextField
                    label="Rate this vehicle (1-5)"
                    type="number"
                    inputProps={{ min: "1", max: "5", step: "1" }}
                    value={rating}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value === '' || (value >= 1 && value <= 5)) {
                            setRating(value);
                        }
                    }}
                    onKeyPress={(e) => {
                        if (!/[1-5]/.test(e.key) && e.key !== 'Backspace') {
                            e.preventDefault();
                        }
                    }}
                    margin="normal"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-input': {
                            color: 'black',
                        },
                        '& .MuiInputLabel-root': {
                            color: 'black',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'black',
                        },
                        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'black',
                        },
                        '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'black',
                        },
                    }}
                />
                <Button variant="contained" color="primary" onClick={handleRatingSubmit} style={{ marginTop: '10px' }}>
                    Submit Rating
                </Button>
            </RatingBox>

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button 
                    variant="outlined" 
                    color="inherit" 
                    onClick={() => navigate('/vehicles')}
                >
                    Back to Vehicles
                </Button>
            </div>

            <Footer>
                <Typography variant="body2">
                    © 2024 Need4Rent. All Rights Reserved.
                </Typography>
            </Footer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Reserve Vehicle</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Start Date"
                        type="date"
                        fullWidth
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        fullWidth
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">Cancel</Button>
                    <Button onClick={handleSubmitReservation} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
        </VehicleDetailContainer>
    );
};

export default VehicleDetailPage;
