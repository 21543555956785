import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Paper, Grid, Button, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { getReservationDetails, updateReservation, deleteReservation } from '../../routes/api';
import AppHeader from '../AppHeader';
import Swal from 'sweetalert2'; // Import SweetAlert2

const FullWidthContainer = styled('div')({
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    backgroundColor: '#f4f4f4',
});

const StyledDetailContainer = styled(Paper)(({ theme }) => ({
    padding: '30px',
    marginTop: '20px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        padding: '20px',
        marginTop: '10px',
    },
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: '#ff3333',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#ff6666',
    },
    margin: '10px 5px',
}));

const ReservationDetailPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [reservation, setReservation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchReservationDetails = async () => {
            try {
                setLoading(true);
                const response = await getReservationDetails(id);
                if (response.data) {
                    setReservation(response.data);
                    setStartDate(response.data.start_date);
                    setEndDate(response.data.end_date);
                } else {
                    throw new Error('Reservation not found.');
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: err.message,
                });
            } finally {
                setLoading(false);
            }
        };
        fetchReservationDetails();
    }, [id]);

    const handleCancelEdit = () => {
        setIsEditing(false);
        setStartDate(reservation.start_date);
        setEndDate(reservation.end_date);
    };

    const handleUpdateReservation = async () => {
        try {
            const updatedData = { start_date: startDate, end_date: endDate };
            if (!reservation.vehicle_id) {
                throw new Error('The vehicle ID field is required.');
            }
            updatedData.vehicle_id = reservation.vehicle_id;
            
            const response = await updateReservation(id, updatedData);
           
            if (response.status === 200 || response.status === 204) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Reservation updated successfully!',
                });
                setIsEditing(false);
                window.location.reload();
            } else {
            
                Swal.fire({
                    icon: 'success',
                    title: 'Update Succesfuly',
                    text: response.data.message,
                });
            }
        } catch (err) {
            console.error(err); 
            if (err.response) {
                Swal.fire({
                    icon: 'error',
                    title: 'Update Failed',
                    text: err.response.data.message || 'An unexpected error occurred.',
                });
            } else if (err.request) {
                Swal.fire({
                    icon: 'error',
                    title: 'No Response',
                    text: 'No response received from the server. Please try again later.',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Unexpected Error',
                    text: 'An unexpected error occurred while updating the reservation.',
                });
            }
        }
    };

    const handleCancelReservation = async () => {
        try {
            await deleteReservation(id);
            navigate('/reservations');
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Cancellation Failed',
                text: 'Failed to cancel reservation.',
            });
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <FullWidthContainer>
            <AppHeader userId={userId} />
            <Grid container justifyContent="center" style={{ flexGrow: 1, width: '100%' }}>
                <Grid item xs={12} style={{ width: '100%' }}>
                    {reservation ? (
                        <StyledDetailContainer>
                            <Typography variant="h4" align="center" gutterBottom>
                                Reservation Details
                            </Typography>
                            {isEditing ? (
                                <>
                                    <Typography variant="body1">
                                        <strong>Reservation ID:</strong> {reservation.id}
                                    </Typography>
                                    <TextField
                                        label="Start Date"
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <TextField
                                        label="End Date"
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <Button variant="contained" color="primary" onClick={handleUpdateReservation}>
                                            Save Changes
                                        </Button>
                                        <Button variant="outlined" color="secondary" onClick={handleCancelEdit} style={{ marginLeft: '10px' }}>
                                            Back
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1">
                                        <strong>Vehicle ID:</strong> {reservation.vehicle_id}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>User ID:</strong> {reservation.user_id}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Start Date:</strong> {new Date(reservation.start_date).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>End Date:</strong> {new Date(reservation.end_date).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Status:</strong> {reservation.status}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Total Price:</strong> ${parseFloat(reservation.total_price).toFixed(2)}
                                    </Typography>

                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        {reservation.status !== 'Cancelled' && (
                                            <>
                                                <StyledButton onClick={handleCancelReservation}>Cancel Reservation</StyledButton>
                                                <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
                                                    Edit Reservation
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </StyledDetailContainer>
                    ) : (
                        <Typography variant="body1" align="center" color="error">
                            {reservation.error ? `Error: ${reservation.error}` : 'No reservation details available.'}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </FullWidthContainer>
    );
};

export default ReservationDetailPage;
