import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { getVehicles } from '../routes/api'; 
import 'leaflet/dist/leaflet.css';
import MapResizer from './MapResizer';

const VehicleTrackingPage = () => {
    const [vehicles, setVehicles] = useState([]);
    const [error, setError] = useState(null);

  

    const vehicleIcon = new L.Icon({
        iconUrl: '/car-icon.png',
        iconSize: [30, 30],
        popupAnchor: [0, -15],
    });

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <MapContainer center={[42.4411, 19.2636]} zoom={8} style={{ height: '100%', width: '100%' }}>
                <MapResizer />
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {error && (
                    <div style={{ position: 'absolute', top: '20px', left: '20px', backgroundColor: 'white', padding: '10px' }}>
                        {error}
                    </div>
                )}
                {vehicles.map((vehicle) => (
                    <Marker
                        key={vehicle.id}
                        position={[vehicle.lat, vehicle.lng]} 
                        icon={vehicleIcon}
                    >
                        <Popup>
                            <strong>{vehicle.name}</strong><br />
                            Lokacija: {vehicle.lat}, {vehicle.lng}
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default VehicleTrackingPage;
