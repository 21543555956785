import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const FooterContainer = styled('footer')({
  backgroundColor: 'black',
  color: '#fff',
  padding: '40px 0',
  marginTop: 'auto',
});

const FooterHeading = styled(Typography)({
  color: '#FF4646',
  fontWeight: 'bold',
  marginBottom: '1rem',
});

const FooterLink = styled(Typography)({
  color: '#00BFFF',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ContactInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

const Footer = () => {
  return (
    <FooterContainer>
      <Grid container justifyContent="center" spacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} sm={4}>
          <FooterHeading variant="h6">Need4Rent RENT A CAR</FooterHeading>
          <Typography variant="body2">
            Our desire to contribute maximally to the transportation sector has defined us as:
            The most flexible rent-a-car agency in Montenegro. We have rules, but we are always
            ready to meet special needs. Vehicle delivery and pickup are available at any location
            within Montenegro, as well as from neighboring countries.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FooterHeading variant="h6">Contact</FooterHeading>
          <ContactInfo>
            <LocationOnIcon sx={{ color: '#FF4646', marginRight: '8px' }} />
            <Typography variant="body2">Podgorica , Tirana , Sarajevo , Moskva </Typography>
          </ContactInfo>
          <ContactInfo>
            <PhoneIcon sx={{ color: '#FF4646', marginRight: '8px' }} />
            <Typography variant="body2">+382 69 990 372</Typography>
          </ContactInfo>
          <ContactInfo>
            <Typography variant="body2">WhatsApp | Telegram | Instagram </Typography>
          </ContactInfo>
          <ContactInfo>
            <EmailIcon sx={{ color: '#FF4646', marginRight: '8px' }} />
            <Typography variant="body2">info@Need4rent.com</Typography>
          </ContactInfo>
          <ContactInfo>
            <AccessTimeIcon sx={{ color: '#FF4646', marginRight: '8px' }} />
            <Typography variant="body2">08:00 – 20:00 | Every Day</Typography>
          </ContactInfo>
        </Grid>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;
