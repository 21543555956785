import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Background = styled(Box)({
  background: 'linear-gradient(black, #ff3333, black)',
  padding: '40px 0',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
});

const GradientText = styled(Typography)( {
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '40px',
  color: '#fff', // Set the text color to white
});

const AboutPaper = styled(Paper)({
  padding: '30px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: '#fff',
  marginTop: '20px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
  borderRadius: '12px',
  animation: `${fadeIn} 0.8s ease-in-out`,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.7)',
  },
});

const AboutUsPage = () => {
  return (
    <Background>
      <Container maxWidth="md">
        <Box textAlign="center">
          <GradientText variant="h3">About Need4Rent</GradientText>
          <AboutPaper>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Need4Rent is committed to providing the best car rental experience possible. Whether you're traveling for business or leisure, our service is designed to offer you reliable and affordable vehicles. Our growing fleet of cars includes economy, luxury, and more to suit every need.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We believe renting a car should be simple, fast, and stress-free. That's why we’ve designed an easy-to-use platform and a customer service team ready to assist you 24/7. Drive with confidence, drive with Need4Rent.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We operate in multiple cities, offering flexibility with pick-up and drop-off locations to make your experience seamless.
            </Typography>
          </AboutPaper>
        </Box>
        <Box mt={5}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <AboutPaper>
                <Typography variant="h6" sx={{ mb: 2 }}>Our Mission</Typography>
                <Typography variant="body2">
                  To provide an unparalleled car rental experience that meets our customers' needs with the utmost convenience and reliability.
                </Typography>
              </AboutPaper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <AboutPaper>
                <Typography variant="h6" sx={{ mb: 2 }}>Our Vision</Typography>
                <Typography variant="body2">
                  To become the leading car rental service recognized for our customer satisfaction and quality of service.
                </Typography>
              </AboutPaper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <AboutPaper>
                <Typography variant="h6" sx={{ mb: 2 }}>Our Values</Typography>
                <Typography variant="body2">
                  Integrity, customer-centricity, and innovation drive us to exceed expectations and deliver the best service possible.
                </Typography>
              </AboutPaper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Background>
  );
};

export default AboutUsPage;
