import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { 
    RegisterPage,           LoginPage,      HomePage, 
    NotFoundPage,           VehiclesPage,   VehicleDetailPage,
    UserProfilePage,        EditUserPage,   ReservationPage,
    ReservationDetailPage,  AboutUsPage,    VehicleTrackingPage,
    ForgotPassword
} from './pages'; 
import ProtectedRoute from './components/ProtectedRoute';


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    {/* Public */}
                    <Route path="/login"            element={<LoginPage         />} />
                    <Route path="/register"         element={<RegisterPage      />} />
                    <Route path="/forgot-password"  element={<ForgotPassword     />} />
                    
                    {/* Protected (Auth) */}
                    <Route path="/"                 element={<ProtectedRoute element={HomePage} />} />
                    <Route path="/vehicles"         element={<ProtectedRoute element={VehiclesPage} />} />
                    <Route path="/vehicle/:id"      element={<ProtectedRoute element={VehicleDetailPage} />} />
                    <Route path="/users/:id"        element={<ProtectedRoute element={UserProfilePage} />} />
                    <Route path="/edit-user/:id"    element={<ProtectedRoute element={EditUserPage} />} />
                    <Route path="/reservations"     element={<ProtectedRoute element={ReservationPage} />} />
                    <Route path="/reservation/:id"  element={<ProtectedRoute element={ReservationDetailPage} />} />
                    <Route path="/about-us"         element={<ProtectedRoute element={AboutUsPage}   />} />
                    <Route path="/vehicle-tracking" element={<ProtectedRoute element={VehicleTrackingPage} />} />
                    
                    {/* 404 */}
                    <Route path="*"                 element={<NotFoundPage      />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
