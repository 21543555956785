import apiRequest from '../services/apiRequest';

/*           name                      data                         method     route                     data        auth */
export const registerUser           = (formData)     => apiRequest('post',   '/register',                formData        );
export const loginUser              = (formData)     => apiRequest('post',   '/login',                   formData        );
export const logoutUser             = ()             => apiRequest('post',   '/logout',                  {},         true);

export const getUserProfile         = (id)           => apiRequest('get',    `/users/${id}`,             {},         true);
export const updateUserProfile      = (id, formData) => apiRequest('post',   `/users/${id}`,             formData,   true);
export const deleteUserProfile      = (id)           => apiRequest('delete', `/users/${id}`,             {},         true);

export const getVehicles            = (page = 1)     => apiRequest('get',    `/vehicles?page=${page}`,   {},         true); 
export const getVehicleDetails      = (id)           => apiRequest('get',    `/vehicles/${id}`,          {},         true);

export const getReservations        = ()             => apiRequest('get',    '/reservations',            {},         true); 
export const getReservationDetails  = (id)           => apiRequest('get',    `/reservations/${id}`,      {},         true);
export const createReservation      = (formData)     => apiRequest('post',   '/reservations',            formData,   true);
export const updateReservation      = (id, formData) => apiRequest('put',    `/reservations/${id}`,      formData,   true);
export const deleteReservation      = (id)           => apiRequest('delete', `/reservations/${id}`,      {},         true);

export const getVehicleAvailability = (id)           => apiRequest('get',    `/availability/${id}`,      {},         true);

export const getVehicleReviews      = (vehicleId)    => apiRequest('get',    `/reviews?vehicle_id=${vehicleId}`, {}, true);
export const createVehicleReview    = (formData)     => apiRequest('post',   '/reviews',                 formData,   true);

export const forgotPassword         = (email)        => apiRequest('post',   '/forgot-password',         { email }       );
