import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUserProfile as getUserData } from '../routes/api';

const Header = styled(AppBar)({
  position: 'static',
  background: 'linear-gradient(135deg, black, darkred)',
});

const glowAnimation = keyframes`
  0% { text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red; }
  100% { text-shadow: 0 0 20px red, 0 0 30px red, 0 0 40px red; }
`;

const Branding = styled(Typography)({
  fontSize: '36px',
  fontWeight: 'bold',
  textShadow: '3px 3px 8px black',
  background: 'linear-gradient(135deg, #ff6666, #ff3333)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textAlign: 'left',
  flexGrow: 1,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    animation: `${glowAnimation} 1s ease-in-out infinite alternate`,
  },
});

const AboutUsButton = styled(Button)({
  color: '#fff',
  backgroundColor: 'red',
  '&:hover': {
    backgroundColor: '#b30000',
  },
  fontWeight: 'bold',
});

const BackButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  position: 'fixed',
  top: '75px',
  left: '20px',
  zIndex: 1000,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'darkred',
    transform: 'scale(1.1)',
    boxShadow: '0px 6px 15px rgba(255, 0, 0, 0.5)',
  },
});

const AppHeader = ({ userId, onLogout, onViewProfile }) => {
  const [userAvatar, setUserAvatar] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate();
  
  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
  const DEFAULT_AVATAR = 'http://localhost:8000/images/default.png';

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchUserAvatar = async () => {
    try {
      const userData = await getUserData(userId);
      if (userData.data && userData.data.user) {
        const picture = userData.data.user.picture;
        if (picture) {
          setUserAvatar(`${IMAGE_URL}/${picture}`);
        } else {
          setUserAvatar(DEFAULT_AVATAR);
        }
      } else {
        setUserAvatar(DEFAULT_AVATAR);
      }
    } catch (error) {
      setUserAvatar(DEFAULT_AVATAR);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserAvatar();
    }
  }, [userId]);

  return (
    <>
      <BackButton onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </BackButton>

      <Header>
        <Toolbar>
          <Branding onClick={() => navigate('/')}>Need 4 Rent</Branding>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <img
              src={userAvatar}
              alt="User Avatar"
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
              onClick={handleAvatarClick}
            />
          )}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onViewProfile}>Profile</MenuItem>
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </Header>
    </>
  );
};

export default AppHeader;
