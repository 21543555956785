import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import { forgotPassword } from '../../routes/api';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await forgotPassword(email);
            setMessage('Check your email for the password reset link.');
        } catch (error) {
            setMessage('Error sending email. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box 
                component="form"
                onSubmit={handleSubmit}
                sx={{ mt: 5, display: 'flex', flexDirection: 'column', gap: 2 }}
            >
                <Typography variant="h4" gutterBottom>
                    Forgot Password
                </Typography>

                <TextField
                    label="Enter your email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                    disabled={loading}
                />

                <Button 
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    fullWidth
                >
                    {loading ? 'Sending...' : 'Send Reset Link'}
                </Button>

                {message && (
                    <Typography color="error" variant="body1">
                        {message}
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default ForgotPassword;

