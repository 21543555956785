import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getVehicles } from '../../routes/api';
import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Button,
    CircularProgress,
    Pagination,
    MenuItem,
    AppBar,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import AppHeader from '../AppHeader';

const Header = styled(AppBar)({
    position: 'static',
    background: 'linear-gradient(135deg, black, red)',
});

const VehiclesPageContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, black, red)',
    padding: '100px 0 20px',
});

const PageTitle = styled(Typography)({
    fontSize: '2.5rem',
    fontWeight: 700,
    color: 'white',
    marginBottom: '20px',
});

const VehicleCard = styled(Card)({
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
    },
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '15px',
});

const VehicleCardMedia = styled(CardMedia)({
    height: 150,
    backgroundSize: 'cover',
});

const BrightButton = styled(Button)({
    backgroundColor: '#D32F2F',
    color: 'white',
    '&:hover': {
        backgroundColor: '#A00000',
    },
    marginTop: '10px',
});

const PaginationContainer = styled('div')({
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '10px 0',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
});

const FilterContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    position: 'absolute',
    right: '40px',
    top: '150px',
    backgroundColor: 'white',
});

const CustomInputLabel = styled(InputLabel)({
    color: 'white',
    '&.Mui-focused': {
        color: 'white',
    },
    '&:hover': {
        textShadow: '0 0 5px white, 0 0 10px white, 0 0 15px white',
    },
});

const VehiclesPage = () => {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortOption, setSortOption] = useState('A-Z');
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');

    const fetchVehicles = async (page = 1) => {
        try {
            setLoading(true);
            const response = await getVehicles(page);
            if (response.data && Array.isArray(response.data)) {
                let fetchedVehicles = response.data;

                if (sortOption === 'A-Z') {
                    fetchedVehicles.sort((a, b) => (a.brand + ' ' + a.model).localeCompare(b.brand + ' ' + b.model));
                } else if (sortOption === 'priceHighToLow') {
                    fetchedVehicles.sort((a, b) => b.price_per_day - a.price_per_day);
                } else if (sortOption === 'priceLowToHigh') {
                    fetchedVehicles.sort((a, b) => a.price_per_day - b.price_per_day);
                }

                setVehicles(fetchedVehicles);
                setTotalPages(Math.ceil(response.total / response.per_page));
                setCurrentPage(page);
            } else {
                console.error('No vehicles found or data is not an array.');
            }
        } catch (error) {
            console.error("Error fetching vehicles:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVehicles(currentPage);
    }, [currentPage, sortOption]);

    const handleViewProfile = () => {
        navigate(`/users/${userId}`);
    };

    const handleLogout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        navigate('/login');
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
            </div>
        );
    }

    if (vehicles.length === 0) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography variant="h5" color="white">No vehicles available at the moment.</Typography>
            </div>
        );
    }

    return (
        <>
            <AppHeader userId={userId} onLogout={handleLogout} onViewProfile={handleViewProfile} />
            <VehiclesPageContainer>
                <PageTitle align="center">
                    Available Vehicles
                </PageTitle>

                <FilterContainer>
                    <FormControl variant="outlined" style={{ minWidth: 120 }}>
                        <CustomInputLabel style={{color: 'Black' }}>Sort By</CustomInputLabel>
                        <Select
                            value={sortOption}
                            onChange={(e) => setSortOption(e.target.value)}
                            label="Sort By"
                            
                        >
                            <MenuItem value="A-Z">A-Z</MenuItem>
                            <MenuItem value="priceLowToHigh">Price: Low to High</MenuItem>
                            <MenuItem value="priceHighToLow">Price: High to Low</MenuItem>
                        </Select>
                    </FormControl>
                </FilterContainer>

                <Grid container spacing={3} justifyContent="center" style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    {vehicles.map((vehicle) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={vehicle.id}>
                            <VehicleCard>
                                <VehicleCardMedia
                                    image={`http://backend.tim3.cortexakademija.com/${vehicle.image}` || 'https://gtacars.net/images/0ea9863b0ff61f913b77744cec5d3400'}
                                    title={`${vehicle.brand} ${vehicle.model}`}
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {vehicle.brand} {vehicle.model}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {vehicle.type}
                                    </Typography>
                                    <Typography variant="body1" color="primary">
                                        ${parseFloat(vehicle.price_per_day).toFixed(2)} / day
                                    </Typography>
                                    <BrightButton
                                        variant="contained"
                                        onClick={() => navigate(`/vehicle/${vehicle.id}`)}
                                    >
                                        View Details
                                    </BrightButton>
                                </CardContent>
                            </VehicleCard>
                        </Grid>
                    ))}
                </Grid>

                <PaginationContainer>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                        variant="outlined"
                        color="primary"
                    />
                </PaginationContainer>
            </VehiclesPageContainer>
        </>
    );
};

export default VehiclesPage;
