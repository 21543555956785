import React, { useState } from 'react';
import { loginUser } from '../../routes/api';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Paper, Typography, CircularProgress, TextField, Button } from '@mui/material';

// Styling for the LoginPage container with gradient background
const LoginPageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(135deg, black, red)', // Gradient background to match the theme
});

// Styling for the login form wrapper
const FormWrapper = styled(Paper)(({ theme }) => ({
  padding: '3rem',
  maxWidth: '400px',
  width: '100%',
  textAlign: 'center',
  borderRadius: '20px',
  boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.3)',
  backgroundColor: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background to match the theme
  backdropFilter: 'blur(10px)', // Blurring effect for the background
  position: 'relative',
}));

// Styling for the title text
const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 700,
  color: '#D32F2F', // Bold red to match theme
  marginBottom: '1.5rem',
}));

const LoadingIndicator = styled(CircularProgress)({
  color: 'red',
});

// Styling for the form fields
const StyledTextField = styled(TextField)({
  marginBottom: '1rem',
  backgroundColor: 'white', // Set the background to white
  '& label.Mui-focused': {
    color: '#D32F2F', // Red color when focused
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#D32F2F', // Red underline after input
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D32F2F', // Set border color
    },
    '&:hover fieldset': {
      borderColor: '#D32F2F', // Change border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D32F2F', // Set border color when focused
    },
  },
});

// Styling for the buttons
const StyledButton = styled(Button)({
  marginTop: '1rem',
  width: '100%',
  backgroundColor: '#D32F2F',
  color: 'white',
  '&:hover': {
    backgroundColor: '#A00000', // Darker red on hover
  },
});

// Footer link styling with white text
const FooterLink = styled(Typography)({
  color: 'white', // Change the color to white
  cursor: 'pointer',
  margin: '0.5rem 0',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const LoginPage = () => {
  const [email, setEmail] = useState(''); // State for email
  const [password, setPassword] = useState(''); // State for password
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setIsLoading(true);
    try {
      const response = await loginUser({ email, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.user.id);
        setSuccessMessage('Login successful!');
        navigate('/');
      }
    } catch (error) {
      setErrorMessage('Login failed. ' + (error.response?.data.message || ''));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginPageContainer>
      <FormWrapper elevation={3}>
        <Title variant="h5">Login</Title>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Typography variant="body1" color="white" marginBottom="1rem">
              Please enter your details to login
            </Typography>
            <form onSubmit={handleSubmit}>
              <StyledTextField
                label="Email"
                variant="outlined"
                fullWidth
                onChange={(e) => setEmail(e.target.value)} // Update email state
                value={email}
              />
              <StyledTextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                onChange={(e) => setPassword(e.target.value)} // Update password state
                value={password}
              />
              <StyledButton variant="contained" type="submit">
                Login
              </StyledButton>
            </form>
            {errorMessage && (
              <Typography variant="body2" color="error">{errorMessage}</Typography>
            )}
            <FooterLink onClick={() => navigate('/forgot-password')}>Forgot Password?</FooterLink>
            <FooterLink onClick={() => navigate('/register')}>Don’t have an account? Sign Up</FooterLink>
          </>
        )}
      </FormWrapper>
    </LoginPageContainer>
  );
};

export default LoginPage;
