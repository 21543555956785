import React, { useState } from 'react';
import { registerUser } from '../../routes/api';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { Paper, Typography, CircularProgress, TextField, Button } from '@mui/material';

// Styled container with a similar gradient background as LoginPage
const RegisterPageContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(135deg, black, red)', // Gradient background
});

// Form wrapper styling similar to LoginPage for consistency
const FormWrapper = styled(Paper)(({ theme }) => ({
  padding: '3rem',
  maxWidth: '400px',
  width: '100%',
  textAlign: 'center',
  borderRadius: '20px',
  boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.3)',
  backgroundColor: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
  backdropFilter: 'blur(10px)', // Blur effect
  position: 'relative',
}));

// Title styling similar to LoginPage but with registration focus
const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 700,
  color: '#D32F2F', // Same red color for consistency
  marginBottom: '1.5rem',
}));

const LoadingIndicator = styled(CircularProgress)({
  color: 'red',
});

// Styled TextField to match the LoginPage design
const StyledTextField = styled(TextField)({
  marginBottom: '1rem',
  backgroundColor: 'white',
  '& label.Mui-focused': {
    color: '#D32F2F',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#D32F2F',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D32F2F',
    },
    '&:hover fieldset': {
      borderColor: '#D32F2F',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D32F2F',
    },
  },
});

// Button styling to match the theme
const StyledButton = styled(Button)({
  marginTop: '1rem',
  width: '100%',
  backgroundColor: '#D32F2F',
  color: 'white',
  '&:hover': {
    backgroundColor: '#A00000', // Darker red on hover
  },
});

const RegisterPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const response = await registerUser(formData);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userId', response.data.user.id);
      setSuccessMessage("Registration successful!");
      navigate('/');
    } catch (error) {
      setErrorMessage("Registration failed. " + (error.response?.data.message || ''));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterPageContainer>
      <FormWrapper elevation={3}>
        <Title variant="h5">Register</Title>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {successMessage && <Typography variant="body2" color="success">{successMessage}</Typography>}
            {errorMessage && <Typography variant="body2" color="error">{errorMessage}</Typography>}
            <Typography variant="body1" color="white" marginBottom="1rem">
              Create your account
            </Typography>
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(e.target); }}>
              <StyledTextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
              />
              <StyledTextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
              />
              <StyledTextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                name="password"
              />
              <StyledTextField
                label="Confirm Password"
                type="password"
                variant="outlined"
                fullWidth
                name="password_confirmation"
              />
              <StyledButton variant="contained" type="submit">
                Register
              </StyledButton>
            </form>
            <Typography variant="body2" color="white" marginTop="1rem">
              Already have an account?{' '}
              <span style={{ color: 'Black', cursor: 'pointer' }} onClick={() => navigate('/login')}>
                Login
              </span>
            </Typography>
          </>
        )}
      </FormWrapper>
    </RegisterPageContainer>
  );
};

export default RegisterPage;
