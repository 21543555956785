import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getUserProfile, updateUserProfile } from '../../routes/api';
import { TextField, Button, CircularProgress, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

// Styled container for the page with a black-to-red gradient background
const EditProfileContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to bottom, black, red)', // Black-to-red gradient background
});

// Styled card for the profile editing form
const ProfileFormCard = styled(Box)({
    maxWidth: '600px',
    width: '100%',
    padding: '30px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white background
    borderRadius: '15px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.3)', // Smooth shadow effect
    textAlign: 'center',
    color: '#000',
});

// Styled button for file selection
const StyledButton = styled(Button)({
    marginTop: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '30px',
    backgroundColor: '#ff0000',
    color: '#fff',
    transition: 'transform 0.3s, background-color 0.3s',
    '&:hover': {
        backgroundColor: '#d50000',
        transform: 'scale(1.05)',
    },
});

// Styled cropper container for better visibility
const CropperContainer = styled(Box)({
    marginBottom: '20px',
    '& img': {
        borderRadius: '10px',
    },
});

const EditUserPage = () => {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        picture: null, 
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [cropData, setCropData] = useState(null);
    const cropperRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setIsLoading(true);
                const response = await getUserProfile(id);
                if (response.data && response.data.user) {
                    setUser(response.data.user);
                    setFormData({
                        name: response.data.user.name,
                        email: response.data.user.email,
                        currentPassword: '', 
                        newPassword: '',
                        confirmNewPassword: '',
                        picture: null, 
                    });
                } else {
                    throw new Error('User not found.');
                }
            } catch (err) {
                setErrors({ general: err.message });
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserProfile();
    }, [id]);

    const handleChange = (e) => {
        if (e.target.name === 'picture') {
            const file = e.target.files[0];
            setFormData({
                ...formData,
                picture: file,
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            const croppedCanvas = cropper.getCroppedCanvas();
            if (croppedCanvas) {
                setCropData(croppedCanvas.toDataURL());
            }
        }
    };

    useEffect(() => {
        if (formData.picture && cropperRef.current) {
            const cropper = cropperRef.current?.cropper;
            const imageURL = URL.createObjectURL(formData.picture);
            cropper.replace(imageURL);
        }
    }, [formData.picture]);

    const validate = () => {
        let validationErrors = {};

        if (!formData.name) {
            validationErrors.name = 'Name is required';
        }

        if (!formData.email) {
            validationErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            validationErrors.email = 'Invalid email address';
        }

        if (formData.newPassword && formData.newPassword.length < 8) {
            validationErrors.newPassword = 'New password must be at least 8 characters long';
        }

        if (formData.newPassword !== formData.confirmNewPassword) {
            validationErrors.confirmNewPassword = 'Passwords do not match';
        }

        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);

        try {
            const dataToSend = new FormData();
            dataToSend.append('_method', 'PUT'); 
            dataToSend.append('name', formData.name);
            dataToSend.append('email', formData.email);
            dataToSend.append('newPassword', formData.newPassword);
            dataToSend.append('confirmNewPassword', formData.confirmNewPassword);

            if (formData.picture) {
                dataToSend.append('picture', formData.picture);
            } else if (cropData) {
                dataToSend.append('picture', cropData); 
            }

            const response = await updateUserProfile(id, dataToSend);
            setSuccessMessage(response.data.message);
            setErrors({});
            navigate(`/users/${id}`);
        } catch (error) {
            setErrors(error.response?.data?.errors || { general: 'Failed to update profile!' });
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <EditProfileContainer>
                <CircularProgress />
            </EditProfileContainer>
        );
    }

    return (
        <EditProfileContainer>
            <ProfileFormCard>
                <Typography variant="h4" gutterBottom>Edit Your Profile</Typography>
                {successMessage && <Typography color="success.main">{successMessage}</Typography>}

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name || ''}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email || ''}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        label="New Password"
                        name="newPassword"
                        type="password"
                        value={formData.newPassword}
                        onChange={handleChange}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword || ''}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        label="Confirm New Password"
                        name="confirmNewPassword"
                        type="password"
                        value={formData.confirmNewPassword}
                        onChange={handleChange}
                        error={!!errors.confirmNewPassword}
                        helperText={errors.confirmNewPassword || ''}
                        margin="normal"
                    />

                    <Box sx={{ marginBottom: '20px', textAlign: 'center' }}>
                        <input
                            type="file"
                            ref={inputRef}
                            name="picture"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                        />
                        <StyledButton
                            variant="outlined"
                            color="primary"
                            onClick={() => inputRef.current.click()}
                        >
                            Choose Image
                        </StyledButton>
                    </Box>

                    {formData.picture && (
                        <CropperContainer>
                            <Cropper
                                src={URL.createObjectURL(formData.picture)}
                                style={{ height: 400, width: '100%' }}
                                aspectRatio={1}
                                guides={false}
                                ref={cropperRef}
                            />
                            <StyledButton variant="contained" color="secondary" onClick={handleCrop}>
                                Crop Image
                            </StyledButton>
                        </CropperContainer>
                    )}

                    <StyledButton
                        type="submit"
                        variant="contained"
                    >
                        Update Profile
                    </StyledButton>
                </form>

                {errors.general && (
                    <Typography variant="body2" color="error">
                        {errors.general}
                    </Typography>
                )}
            </ProfileFormCard>
        </EditProfileContainer>
    );
};

export default EditUserPage;
