import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getReservations, deleteReservation } from '../../routes/api';
import { CircularProgress, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Grid, Paper, Container } from '@mui/material';
import { styled } from '@mui/system';
import AppHeader from '../AppHeader';

const FullWidthContainer = styled(Container)({
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    backgroundColor: '#f4f4f4',
});

const StyledTableContainer = styled(Paper)({
    width: '100%',
    marginTop: '20px',
    padding: '0px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    overflowX: 'auto',
});

const StyledTable = styled(Table)({
    minWidth: 650,
    width: '100%',
});

const StyledButton = styled(Button)({
    backgroundColor: '#ff3333',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#ff6666',
    },
    margin: '0 5px',
});

const ReservationsPage = () => {
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const userId = localStorage.getItem('userId');

    const fetchReservations = async (page = 1) => {
        try {
            setLoading(true);
            const response = await getReservations(page);
            if (response.data && Array.isArray(response.data)) {
                setReservations(response.data);  
                setTotalPages(response.links.length - 2);
                setCurrentPage(page);
            }
        } catch (error) {
            console.error("Error fetching reservations:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReservations(currentPage);
    }, [currentPage]);

    const handleCancelReservation = async (reservationId) => {
        try {
            await deleteReservation(reservationId);
            fetchReservations(currentPage); // Ponovno učitajte rezervacije nakon otkazivanja
        } catch (error) {
            console.error("Failed to cancel reservation:", error);
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <AppHeader userId={userId} />
            <Grid container justifyContent="center" style={{ flexGrow: 1, width: '100%' }}>
                <Grid item xs={12} style={{ width: '100%' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Reservations
                    </Typography>
                    {reservations.length === 0 ? (
                        <Typography variant="body1" align="center">No reservations available.</Typography>
                    ) : (
                        <StyledTableContainer>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Reservation ID</strong></TableCell>
                                        <TableCell><strong>Vehicle ID</strong></TableCell>
                                        <TableCell><strong>User ID</strong></TableCell>
                                        <TableCell><strong>Start Date</strong></TableCell>
                                        <TableCell><strong>End Date</strong></TableCell>
                                        <TableCell><strong>Status</strong></TableCell>
                                        <TableCell><strong>Total Price</strong></TableCell>
                                        <TableCell><strong>Actions</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reservations.map((reservation) => (
                                        <TableRow key={reservation.id}>
                                            <TableCell>{reservation.id}</TableCell>
                                            <TableCell>{reservation.vehicle_id}</TableCell>
                                            <TableCell>{reservation.user_id}</TableCell>
                                            <TableCell>{new Date(reservation.start_date).toLocaleDateString()}</TableCell>
                                            <TableCell>{new Date(reservation.end_date).toLocaleDateString()}</TableCell>
                                            <TableCell>{reservation.status}</TableCell>
                                            <TableCell>${parseFloat(reservation.total_price).toFixed(2)}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    component={Link}
                                                    to={`/reservation/${reservation.id}`}
                                                    size="small"
                                                    style={{ color: '#ff3333', borderColor: '#ff3333' }}
                                                >
                                                    View Details
                                                </Button>
                                                {reservation.status !== 'Cancelled' && (
                                                    <StyledButton
                                                        onClick={() => handleCancelReservation(reservation.id)}
                                                    >
                                                        Cancel
                                                    </StyledButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                        </StyledTableContainer>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ReservationsPage;
