import React from 'react';
import { styled } from '@mui/system';

const StarContainer = styled('div')({
    display: 'flex',
});

const Star = styled('span')(({ filled }) => ({
    fontSize: '24px',
    color: filled ? '#FFD700' : '#ccc',
    cursor: 'pointer',
}));

const StarRating = ({ rating }) => {
    return (
        <StarContainer>
            {Array.from({ length: 5 }, (_, index) => (
                <Star key={index} filled={index < rating}>
                    ★
                </Star>
            ))}
        </StarContainer>
    );
};

export default StarRating;
