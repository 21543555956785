import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getUserProfile, deleteUserProfile } from '../../routes/api';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import Swal from 'sweetalert2';
import { logoutUser } from '../../routes/api';

const ProfileContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to bottom, black, red)', 
});

const ProfileCard = styled(Box)({
    maxWidth: '600px',
    width: '100%',
    padding: '30px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', 
    borderRadius: '15px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.3)', 
    textAlign: 'center', 
    color: '#000',
    transition: 'transform 0.2s', 
    '&:hover': {
        transform: 'scale(1.02)', 
    },
});

const ProfilePicture = styled('img')({
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: '15px',
    transition: 'box-shadow 0.3s, transform 0.3s',
    '&:hover': {
        boxShadow: '0px 0px 15px rgba(255, 0, 0, 0.7)', 
        transform: 'scale(1.05)', 
    },
});

const StyledButton = styled(Button)({
    fontSize: '16px',
    padding: '10px 20px',
    borderRadius: '30px',
    backgroundColor: '#ff0000', 
    color: '#fff',
    transition: 'transform 0.3s, background-color 0.3s',
    '&:hover': {
        backgroundColor: '#d50000',
        transform: 'scale(1.05)', 
    },
});

const UserProfilePage = () => {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setLoading(true);
                const response = await getUserProfile(id);
                if (response.data && response.data.user) {
                    setUser(response.data.user);
                } else {
                    throw new Error('User not found.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [id]);

    if (loading) {
        return (
            <ProfileContainer>
                <CircularProgress />
            </ProfileContainer>
        );
    }

    if (error) {
        return (
            <ProfileContainer>
                <Typography color="error">Error: {error}</Typography>
            </ProfileContainer>
        );
    }

    if (!user) {
        return (
            <ProfileContainer>
                <Typography>No user profile available.</Typography>
            </ProfileContainer>
        );
    }

    const joinedDate = new Date(user.created_at).toLocaleDateString();

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const handleEdit = () => {
        navigate(`/edit-user/${user.id}`);
    };

    const handleLogout = async () => {
        await logoutUser();
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        navigate('/login');
    };

    const handleDeleteAccount = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Once deleted, you won't be able to recover your account!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteUserProfile(user.id);
                    Swal.fire('Deleted!', 'Your account has been deleted.', 'success');
                    localStorage.removeItem('token');
                    localStorage.removeItem('userId');
                    navigate('/login');
                } catch (error) {
                    Swal.fire('Error!', 'Something went wrong. Please try again.', 'error');
                }
            }
        });
    };

    return (
        <ProfileContainer>
            <ProfileCard>
                <Typography variant="h4" gutterBottom>User Profile</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                    <ProfilePicture
                        src={user.picture ? 
                             (isValidUrl(user.picture) ? user.picture : `${process.env.REACT_APP_IMAGE_URL}/${user.picture}`) 
                             : `http://backend.tim3.cortexakademija.com/images/default.png`}
                        alt="User profile"
                    />
                    
                    <Typography variant="h6"><strong>Name:</strong> {user.name}</Typography>
                    <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
                    <Typography variant="body1"><strong>Role:</strong> {user.is_admin === 1 ? 'Admin' : 'User'}</Typography>
                    <Typography variant="body2"><strong>Joined:</strong> {joinedDate}</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <StyledButton variant="contained" onClick={handleEdit}>
                        Edit Profile
                    </StyledButton>
                    <StyledButton variant="contained" color="error" onClick={handleDeleteAccount}>
                        Delete Account
                    </StyledButton>
                    <StyledButton variant="contained" color="error" onClick={handleLogout}>
                        Logout
                    </StyledButton>
                </Box>
            </ProfileCard>
        </ProfileContainer>
    );
};

export default UserProfilePage;

