import { useMap } from 'react-leaflet';
import { useEffect } from 'react';

const MapResizer = () => {
  const map = useMap();
  
  useEffect(() => {
    map.invalidateSize();
  }, [map]);

  return null;
};

export default MapResizer;

